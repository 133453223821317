
#projects {
    position: relative;
    
    @media (max-width: 1000px) {
      height: fit-content;
    }

      .grid-item-project-container {
        max-width: 500px;
      }
      .projects-sub-title {
        font-weight: 600;
        font-size: 1.75rem;
        margin-bottom: 2rem;
      } 
      .project-img {
        border-radius: 2rem;
        width: 90%;
        height: 90%;
      }
      
      .project-title {
        margin: 1rem;
 
      }
      
    
  }

