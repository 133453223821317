
#contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50vh;
    @media (max-width: 1000px) {
      height: fit-content;
    }
    @media (max-width: 1000px) {
      height: 60vh;
    }

    .contact-info-upper-container {
      display: flex;
      justify-content: center;
      border-radius: 2rem;
      border: rgb(53, 53, 53) 0.1rem solid;
      border-color: rgb(163, 163, 163);
      background: (250, 250, 250);
      margin: 2rem auto;
      padding: 0.5rem;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .contact-info-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        margin: 1rem;
        @media (max-width: 600px) {
          margin: 0;
        }
        p {
          font-size: larger;
          @media (max-width: 600px) {
            font-size: 1rem;
          }
        }
        .contact-icon {
          cursor: default;
        }
        .email-icon {
          height: 2.5rem;
        }
      }
    }
  }
  
  
  