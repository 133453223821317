
/* DESKTOP NAV */
nav {
    justify-content: space-around;
    align-items: center;
    height: 10vh;
    display: flex;

    ul {
        display: flex;
        gap: 2rem;
        list-style: none;
        font-size: 1.5rem;
        a {
            text-decoration: none;
            
            &:hover{
              text-decoration: underline;
              text-underline-offset: 1rem;
              
            }
            @media (max-width: 600px) {
              font-size: 1rem;
            }
          }
 
    }
    .logo {
      color: var(--secondary-color);
      font-size: 2rem;
      &:hover {
        cursor: default;
      }
      @media (max-width: 600px) {
          font-size: 1.5rem;
      }
    }
      
}


/* HAMBURGER MENU */
#desktop-nav {
  @media (max-width:1000px) {
    display: none;
  }
}

#hamburger-nav {
    display: none;
    @media (max-width:1000px) {
      display: flex;
    }
  }
  
  .hamburger-menu {
    position: relative;
    display: inline-block;
    .menu-links {
      box-shadow: 0 0 1.5px gainsboro
    }
  }
  
  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px;
    width: 30px;
    cursor: pointer;
  }
  
  .hamburger-icon span {
    width: 100%;
    height: 2px;
    background-color: var(--secondary-color);
    transition: all 0.3 ease-in-out;
  }
  
  .menu-links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--primary-color);
    width: fit-content;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3 ease-in-out;
    text-align: center;
    &.open {
      max-height: 300px;
    }
    li{
      list-style: none;
      padding: 10px;
      a {
        font-size: 1.5rem;
        text-decoration: none;
        transition: all 0.3 ease-in-out;
      }
    }
  }
 
  
  .hamburger-icon.open span {
    &:first-child {
      transform: rotate(45deg) translate(10px, 5px);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:last-child {
      transform: rotate(-45deg) translate(10px, -5px);
    }
  }

  
  .hamburger-icon span {
    &:first-child {
      transform: none;
    }
    &:first-child {
      opacity: 1;
    }
    &:first-child {
      transform: none;
    }
  }

