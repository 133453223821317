

#experience {
    height: fit-content;
    position: relative;
    @media (max-width: 1000px) {
      margin-top: 2rem;
    }
      .experience-sub-title {
        font-weight: 600;
        font-size: 1.75rem;
        margin-bottom: 2rem;
        @media (max-width: 600px) {
          font-size: 1.25rem;
        }
      }
      .grid-item-article-container {
        
        display: flex; 
        text-align: initial;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-around;
        article {
          
          display: flex;
          width: 10rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          @media (max-width: 600px) {
            font-size: 1rem;
          }
          .icon {
            cursor: default;
          }
        }
      }
      #experience-grid {
        @media (max-width: 1000px) {
          display: block;
          .grid-item-container {
            margin-top: 2rem;
          }
        }
        
      }
  }

  